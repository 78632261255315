/* eslint-disable eqeqeq, no-unused-vars */

import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ReactTable from 'react-table';
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import {matchSorter} from 'match-sorter';
import jsPDF from "jspdf";
import { Typeahead } from 'react-bootstrap-typeahead';

class DataLoa extends Component {

    _isMounted = false; 
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataInvoice:[],
            modal: false,
            modalDelete:false,
            modalDetail:false,
            modalDetailAdd:false,
            modalDetailEdit:false,
            modalDetailDelete:false,
            title: '',
            titleDelete: '',
            logName:'',
            editingRow: null,
            deletingRow: null,
            editingRowDetail: null,
            deletingRowDetail: null,
            detailRow: {},
            errors: {},
            operation: '',
            btnDisabled: false,
            dataProfile: [],
            dataProgram: [],
            dataMataUang: [],
            toastId: null,
            dataLoa: [],
            model: {
                loa_number: "",
                code_student_loa: "",
                program: "",
                code_school_rep: "",
                code_student_program: "",
                date_program_start: "",
                date_program_finish: "",
                periode_study: "",
                amount: "",
                commission: "",
                total_invoice: ""
            },
            model_detail: {
                code_invoice: "",
                invoice_name: "",
                due_date: new Date().toISOString().slice(0,10),
                invoice_amount: "",
                currency: ""
            },
            display: "none",
            detail_invoice: []
        }
    }

    componentDidMount() {
        this.getMataUang();
    }

    getInvoice(row) {
        const url = API_URL.superStudent+'/student-invoice/invoice/'+row.code_student_loa;
        axios.get(url,{
            headers: {
                'Accept': 'application/json', 
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ 
                dataInvoice: data.data,
                modalDetail: true,
                dataLoa: row,
                title: "Detail Invoice"
            })
        })
    }

    getMataUang() {
        const url = API_URL.superStudent+'/finance/currency-all';
        axios.get(url, 
        { 
            headers: { 
                'Accept': 'application/json', 
                'Authorization': `Bearer ${this.props.dataToken}`
            } 
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataMataUang: data })
        });
    }

    setLoaNumber = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.loa_number = newData; 
            return { model };                   
        }, () => {
            this.validateLoaNumber();
        })
    }
    setProgramName = (e) => {
        const id = e.length > 0 ? e[0].pivot.id_program : "";
        const newData = e.length > 0 ? e[0].pivot.code_student_program : "";
        const newDataCampus = e.length > 0 ? e[0].university.code_school_rep : "";

        var date1 = e.length > 0 ? Moment(e[0].pivot.date_program_start) : ""; 
        var date2 = e.length > 0 ? Moment(e[0].pivot.date_program_finish) : ""; 
        var diff_day = e.length > 0 ? date2.diff(date1, 'days') : "";

        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.program = id;
            model.code_student_program = newData;
            model.code_school_rep = newDataCampus;
            model.date_program_start = date1;
            model.date_program_finish = date2;
            model.periode_study = diff_day;
            return { model };                   
        }, () => {
            this.validateProgramName();
        })
    }
    setAmount = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.amount = newData; 
            return { model };                   
        }, () => {
            this.validateAmount();
        })
    }
    setCommission = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.commission = newData; 
            return { model };                   
        }, () => {
            this.validateCommission();
        })
    }
    setInvoiceName = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model_detail = Object.assign({}, prevState.model_detail);  
            model_detail.invoice_name = newData; 
            return { model_detail };                   
        }, () => {
            this.validateInvoiceName();
        })
    }
    setDueDate = date => { 
        this.setState(prevState => {
            let model_detail = Object.assign({}, prevState.model_detail);  
            model_detail.due_date = Moment(date).format('YYYY-MM-DD'); 
            return { model_detail };                   
        })
    }
    setInvoiceAmount = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model_detail = Object.assign({}, prevState.model_detail);  
            model_detail.invoice_amount = newData; 
            return { model_detail };                   
        }, () => {
            this.validateInvoiceAmount();
        })
    }
    setCurrency = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model_detail = Object.assign({}, prevState.model_detail);  
            model_detail.currency = newData; 
            return { model_detail };                   
        }, () => {
            this.validateCurrency();
        })
    }

    validateLoaNumber = () => {
        const data = this.state.model;
        if (data.loa_number === "" || data.loa_number === 0 ) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.loa_number = 'LOA Number Harus Diisi'; 
                errors.loa_numberStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.loa_number = '';    
                errors.loa_numberStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateProgramName = () => {
        const data = this.state.model;
        if (data.program === "" || data.program === 0 ) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.program = 'Nama Program Harus Diisi'; 
                errors.programStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.program = '';    
                errors.programStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateAmount = () => {
        const data = this.state.model;
        if (data.amount === "" || data.amount === 0 ) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.amount = 'Amount Harus Diisi'; 
                errors.amountStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.amount = '';    
                errors.amountStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateCommission = () => {
        const data = this.state.model;
        if (data.commission === "" || data.commission === 0 ) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.commission = 'Commission Harus Diisi'; 
                errors.commissionStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.commission = '';    
                errors.commissionStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateInvoiceName = () => {
        const data = this.state.model;
        if (data.invoice_name === "" || data.invoice_name === 0 ) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.invoice_name = 'Invoice Name Harus Diisi'; 
                errors.invoice_nameStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.invoice_name = '';    
                errors.invoice_nameStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateInvoiceAmount = () => {
        const data = this.state.model;
        if (data.invoice_amount === "" || data.invoice_amount === 0 ) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.invoice_amount = 'Amount Harus Diisi'; 
                errors.invoice_amountStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.invoice_amount = '';    
                errors.invoice_amountStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateCurrency = () => {
        const data = this.state.model;
        if (data.currency === "" || data.currency === 0 ) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.currency = 'Currency Harus Diisi'; 
                errors.currencyStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.currency = '';    
                errors.currencyStatus = true;                             
                return { errors };                   
            })
        }
    }

    hitungTotal = () => {
        let xtotal_invoice = this.state.model.amount * this.state.model.commission / 100;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.total_invoice = xtotal_invoice; 
            return { model };                   
        }, () => {
            
        })
    }

    handleValidation(){
        let formIsValid = true;
        if(!this.state.errors.loa_numberStatus){
            formIsValid = false;
        }
        if(!this.state.errors.programStatus){
            formIsValid = false;
        }
        if(!this.state.errors.amountStatus){
            formIsValid = false;
        } 
        if(!this.state.errors.commissionStatus){
            formIsValid = false;
        } 
        return formIsValid;
    }

    handleValidationDetail(){
        let formIsValid = true;
        if(!this.state.errors.invoice_nameStatus){
            formIsValid = false;
        }
        if(!this.state.errors.invoice_amountStatus){
            formIsValid = false;
        }
        if(!this.state.errors.currencyStatus){
            formIsValid = false;
        }
        return formIsValid;
    }

    addClick(){
        this.setState(prevState => ({ 
            detail_invoice: [...prevState.detail_invoice, {}]
        }))
    }

    removeClick(i){
        let detail_invoice = [...this.state.detail_invoice];
        detail_invoice.splice(i, 1);
        this.setState({ detail_invoice });
    }

    handleChange(i, e) {
        let detail_invoice = [...this.state.detail_invoice];
        detail_invoice[i] = {...detail_invoice[i], [e.target.name]:e.target.value};
        this.setState({ detail_invoice });
    }

    saveData(){
        if(this.handleValidation()){
            this.sendData();
        }else{
            this.validateLoaNumber();
            this.validateProgramName();
            this.validateAmount();
            this.validateCommission();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        let data = this.state.model;

        var formData = new FormData();
        formData.append('loa_number', data.loa_number);
        formData.append('code_student_loa', data.code_student_loa);
        formData.append('code_students', this.props.profile.code_students);
        formData.append('code_student_program', data.code_student_program); 
        formData.append('date_program_start', Moment(data.date_program_start).format('YYYY-MM-DD'));
        formData.append('date_program_finish', Moment(data.date_program_finish).format('YYYY-MM-DD'));
        formData.append('periode_study', data.periode_study);
        formData.append('amount', data.amount); 
        formData.append('commission', data.commission); 
        formData.append('total_invoice', data.total_invoice);

        let url = '/student-loa/master-loa';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/student-loa/master-loa/'+data.code_student_loa
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/student-loa/master-loa/'+data.code_student_loa
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        }).then((reply)=>{
            if(reply.status === 200){
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                } 
                this.closeModal();
                this.props.onReloadLoa();
                this.setState({
                    btnDisabled: false
                });
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    saveDataDetail(){
        if(this.handleValidationDetail()){
            this.sendDataDetail();
        }else{
            this.validateInvoiceName();
            this.validateInvoiceAmount();
            this.validateCurrency();
        }
    }

    async createDataDetail() {
        this.setState({
            btnDisabled: true
        });

        this.state.detail_invoice.map(async (detail) => {
            var formData = new FormData();
            formData.append('code_students', this.props.profile.code_students);
            formData.append('id_student_loa', this.state.dataLoa.id_student_loa); 
            formData.append('code_student_loa', this.state.dataLoa.code_student_loa);
            formData.append('invoice_name', detail.invoice_name); 
            formData.append('due_date', detail.due_date); 
            formData.append('invoice_amount', detail.invoice_amount); 
            formData.append('currency', detail.currency); 

            await axios.post(API_URL.superStudent+'/student-invoice/list', formData, { 
                headers: { 
                    'Authorization': `Bearer ${this.props.dataToken}` 
                }
            }).then((reply)=>{
                toast.update(this.state.toastId, {
                    render: "Input Success !",
                    type: toast.TYPE.SUCCESS,
                    autoClose: true,
                    className: 'rotateY animated'
                })
                this.setState({
                    btnDisabled: false
                });
                this.closeModalDetail();
                this.closeModal();
                this.props.onReloadLoa();
            }).catch(function(reply){
                console.log(reply)
            });
        })
    }

    sendDataDetail() {
        this.setState({
            btnDisabled: true
        });

        let data = this.state.model_detail;

        var formData = new FormData();
        formData.append('code_students', this.props.profile.code_students);
        formData.append('id_students_invoice',data.id_students_invoice);
        formData.append('id_student_loa', this.state.dataLoa.id_student_loa); 
        formData.append('code_student_loa', this.state.dataLoa.code_student_loa); 
        formData.append('code_invoice', data.code_invoice); 
        formData.append('invoice_name', data.invoice_name); 
        formData.append('due_date', data.due_date); 
        formData.append('invoice_amount', data.invoice_amount); 
        formData.append('currency', data.currency); 

        let url = '';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/student-invoice/list/'+data.code_invoice
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/student-invoice/list/'+data.code_invoice
        }

        axios.post(API_URL.superStudent+url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        }).then((reply)=>{
            toast.update(this.state.toastId, {
                render: "Input Success !",
                type: toast.TYPE.SUCCESS,
                autoClose: true,
                className: 'rotateY animated'
            })
            this.setState({
                btnDisabled: false
            });
            this.closeModalDetail();
            this.closeModal();
            this.props.onReloadLoa();
        }).catch(function(reply){
            console.log(reply)
        });
    }

    renderEditDialog() {
        const row = this.state.model;
        const modal = this.state.modal;
        const { title } = this.state;
        return (
            <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>{title}</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <form className="needs-validation" noValidate="" >
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>LOA Number</label>
                                <input className="form-control" name="loa_number" type="text" id="loa_number"  value={row.loa_number}
                                    onChange={(e) => this.setLoaNumber(e)}
                                    onBlur={(e) => this.validateLoaNumber()}
                                    ref={e => this.inputLoaNumber = e}
                                />
                                <span>{this.state.errors.loa_number}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Program Name</label>
                                <Typeahead
                                    id="basic-typeahead"
                                    labelKey="program_name"
                                    name="code_student_program"
                                    value={row.code_student_program}
                                    options={this.props.program}
                                    onChange={(e) => this.setProgramName(e)}
                                    onBlur={(e) => this.validateProgramName()}
                                    ref={e => this.inputProgram = e}
                                    placeholder="--Pilih Program--"
                                />
                                <span>{this.state.errors.programStatus}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Amount</label>
                                <input className="form-control" name="amount" type="number" id="amount"  value={row.amount}
                                    onChange={(e) => this.setAmount(e)}
                                    onBlur={(e) => this.validateAmount()}
                                    ref={e => this.inputAmount = e}
                                />
                                <span>{this.state.errors.amount}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-11 mb-3">
                                <label>Commission Fee</label>
                                <input className="form-control" name="commission" type="number" id="commission"  value={row.commission}
                                    onChange={(e) => this.setCommission(e)}
                                    onBlur={(e) => this.validateCommission()}
                                    ref={e => this.inputCommission = e}
                                />
                                <span>{this.state.errors.commission}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-1 mt-4">
                                <label></label>
                                <button onClick={() => this.hitungTotal()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-calculator" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Total Invoice</label>
                                <input className="form-control" name="total_invoice" type="text" id="total_invoice" disabled value={row.total_invoice} />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderDetailDialog() {
        const { title, modalDetail } = this.state;
        const data = this.state.dataInvoice;
        return (
            <Modal className="modal-dialog" isOpen={modalDetail} toggle={() => this.closeModal()} size="lg">
              <ModalHeader toggle={() => this.closeModal()}>{title}</ModalHeader>
                <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    <div className="media-body">
                        <div className="row">
                            <div className="col-sm-1">
                                <button onClick={() => this.addDetailInv()} className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-plus" style={{ width: 35, padding: 11, fontSize: 20 }}></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <th>Code Student LOA</th>
                                <th>Invoice Name</th>
                                <th>Due Date</th>
                                <th>Invoice Amount</th>
                                <th>Status Payment</th>
                                <th colSpan={2}>Action</th>
                            </thead>
                            <tbody>
                                {data.map((invoice, i) => {
                                    return  <tr>
                                                <td>{invoice.code_student_loa}</td>
                                                <td>{invoice.invoice_name}</td>
                                                <td>{invoice.due_date_format}</td>
                                                <td>{invoice.currency} {invoice.invoice_amount}</td>
                                                <td>{invoice.status_payment_format}</td>
                                                <td><button onClick={() => this.editDetailInv(invoice)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none', display: this.props.display}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></button></td>
                                                <td><button onClick={() => this.deleteDetailInv(invoice)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none',display:this.props.display}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button></td>
                                            </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
    }

    renderDeleteDialog() {
        const { title, modalDelete, rules } = this.state;
          return (
            <Modal className="modal-dialog" isOpen={modalDelete} toggle={() => this.closeModal()} size="lg">
              <ModalHeader toggle={() => this.closeModal()}>{title}</ModalHeader>
                <ModalBody style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                  Delete this data ?
                </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
    }

    renderAddDialogDetail() {
        const row = this.state.model_detail;
        const modal = this.state.modalDetailAdd;
        const { title } = this.state;
        return (
            <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModalDetail()} size="lg">
                <ModalHeader toggle={() => this.closeModalDetail()}>{title}</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <button onClick={this.addClick.bind(this)} className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-plus" style={{ width: 35, padding: 11, fontSize: 15 }}></i></button>
                    <form className="needs-validation" noValidate="">
                        {
                            this.state.detail_invoice.map((detail, i) => (
                                <div className="form-row">
                                    <div className="col-md-3 mb-3">
                                        <label>Invoice Name</label>
                                        <input className="form-control" name="invoice_name" type="text" id="invoice_name"
                                            onChange={this.handleChange.bind(this, i)}
                                        />
                                        <span>{this.state.errors.invoice_name}</span>
                                        <div className="valid-feedback">Looks good!</div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="due_date">Due Date</label>
                                        <input className="form-control" name="due_date" type="date" id="due_date" 
                                            onChange={this.handleChange.bind(this, i)}
                                        />
                                        <span>{this.state.errors.date_program_start}</span>
                                        <div className="valid-feedback">Looks good!</div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label>Invoice Amount</label>
                                        <input className="form-control" name="invoice_amount" type="number" id="invoice_amount"
                                            onChange={this.handleChange.bind(this, i)}
                                        />
                                        <span>{this.state.errors.invoice_amount}</span>
                                        <div className="valid-feedback">Looks good!</div>
                                    </div>
                                    <div className="col-md-2 mb-3">
                                        <label>Currency</label>
                                        <select className="form-control" name="currency"  id="currency"
                                            onChange={this.handleChange.bind(this, i)}
                                        >
                                            <option value="0">--Choose Currency--</option>
                                            {this.state.dataMataUang.map((data1)=>(
                                                <option key={data1.code_mata_uang} value={data1.code_mata_uang}>{data1.code_mata_uang}</option>
                                            ))}
                                        </select>
                                        <span>{this.state.errors.currency}</span>
                                        <div className="valid-feedback">Looks good!</div>
                                    </div>
                                    <div className='col-md-1 mb-3'>
                                        <button onClick={this.removeClick.bind(this, i)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                                    </div>
                                </div>
                            ))
                        }
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.createDataDetail()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModalDetail()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderEditDialogDetail() {
        const row = this.state.model_detail;
        const modal = this.state.modalDetailEdit;
        const { title } = this.state;
        return (
            <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModalDetail()} size="lg">
                <ModalHeader toggle={() => this.closeModalDetail()}>{title}</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <form className="needs-validation" noValidate="" >
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Invoice Name</label>
                                <input className="form-control" name="invoice_name" type="text" id="invoice_name"  value={row.invoice_name}
                                    onChange={(e) => this.setInvoiceName(e)}
                                    onBlur={(e) => this.validateInvoiceName()}
                                    ref={e => this.inputInvoiceName = e}
                                />
                                <span>{this.state.errors.invoice_name}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="due_date">Due Date</label>
                                <DatePicker className="form-control" name="due_date" type="text" id="due_date"  
                                    value={row.due_date}
                                    dateFormat="yyyy-MM-dd"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onChange={this.setDueDate}
                                    ref={e => this.inputDateProgramStart = e}
                                />
                                <span>{this.state.errors.date_program_start}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Invoice Amount</label>
                                <input className="form-control" name="invoice_amount" type="number" id="invoice_amount"  value={row.invoice_amount}
                                    onChange={(e) => this.setInvoiceAmount(e)}
                                    onBlur={(e) => this.validateInvoiceAmount()}
                                    ref={e => this.inputInvoiceAmount = e}
                                />
                                <span>{this.state.errors.invoice_amount}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Currency</label>
                                <select className="form-control" name="currency"  id="currency" value={row.currency}
                                    onChange={(e) => this.setCurrency(e)}
                                    onBlur={(e) => this.validateCurrency()}
                                    ref={e => this.inputCurrency = e}
                                >
                                    <option value="0">--Choose Currency--</option>
                                    {this.state.dataMataUang.map((data1)=>(
                                        <option key={data1.code_mata_uang} value={data1.code_mata_uang}>{data1.code_mata_uang}</option>
                                    ))}
                                </select>
                                <span>{this.state.errors.currency}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveDataDetail()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModalDetail()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderDeleteDialogDetail() {
        const { title, modalDetailDelete, rules } = this.state;
          return (
            <Modal className="modal-dialog" isOpen={modalDetailDelete} toggle={() => this.closeModalDetail()} size="lg">
              <ModalHeader toggle={() => this.closeModalDetail()}>{title}</ModalHeader>
                <ModalBody style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                  Delete this data ?
                </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => this.sendDataDetail()}>Save Changes</Button>
                <Button color="secondary" onClick={() => this.closeModalDetail()}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
    }

    closeModal(){
        this.setState({
            modal: false,
            modalDelete: false,
            modalDetail: false
        });
        this.resetState();
    }

    closeModalDetail(){
        this.setState({
            modalDetailAdd: false,
            modalDetailEdit: false,
            modalDetailDelete: false,
        }, () => {
            this.resetStateDetailEdit();
        });
    }

    resetState(){
        this.setState({
            editingRow: null,
            deletingRow: null,
            model: {
                loa_number: "",
                code_students: this.props.profile.code_students,
                code_school_rep: this.props.profile.code_school_rep,
                program_name: "",
                date_program_start: new Date().toISOString().slice(0,10),
                date_program_finish: new Date().toISOString().slice(0,10),
                periode_study: "",
                amount: "",
                commission: "",
                total_invoice: ""
            },
            errors: {},
        });
    }

    resetStateDetailEdit(){
        this.setState({
            editingRowDetail: null,
            deletingRowDetail: null,
            model_detail: {
                invoice_name: "",
                due_date: new Date().toISOString().slice(0,10),
                invoice_amount: "",
                currency: ""
            },
            errors: {},
        });
    }

    addLoa() {
        this.setState({
            title: 'LOA',
            operation: 'add',
            modal: true,
            logName: 'ADD-LOA',
        });
    }

    editLoa(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            operation: 'edit',
            title:"Edit LOA",
            modal: true,
            logName: 'EDIT-LOA'
        });
    }

    deleteLoa(row) {
        this.setState({
            deletingRow: row,
            model: Object.assign({}, row),
            modalDelete: true,
            operation: 'delete',
            title:"Delete LOA",
            logName: 'DELETE-LOA'
        });
    }

    detailLoa(row) {
        this.getInvoice(row);
    }

    addDetailInv() {
        this.setState({
            title: 'Invoice',
            operation: 'add',
            modalDetailAdd: true,
            logName: 'ADD-INVOICE',
        });
    }

    editDetailInv(row) {
        this.setState({
            editingRowDetail: row,
            model_detail: Object.assign({}, row),
            operation: 'edit',
            title:"Edit Invoice",
            modalDetailEdit: true,
            logName: 'EDIT-INVOICE'
        });
    }

    deleteDetailInv(row) {
        this.setState({
            deletingRowDetail: row,
            model_detail: Object.assign({}, row),
            modalDetailDelete: true,
            operation: 'delete',
            title:"Delete Invoice",
            logName: 'DELETE-INVOICE'
        });
    }

    render() {
        const data = this.props.data
        const columns = [
            {
                Header: 'Code LOA',
                accessor: 'code_student_loa',
                style: {textAlign: 'center'},
            },
            {
                Header: 'LOA Number',
                accessor: 'loa_number',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Campus',
                accessor: 'nama_sekolah',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Program',
                accessor: 'program_name',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Program Date',
                Cell: (row) => (
                  <span>{row.original.date_program_start_format} - {row.original.date_program_finish_format}</span>
                ),
                style: {textAlign: 'center'},
                filterable: false
              },
            {
                Header: 'Periode Study',
                accessor: 'periode_study',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Amount',
                accessor: 'amount_format',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Commission',
                accessor: 'komisi',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Total Invoice',
                accessor: 'total_invoice_format',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <button onClick={() => this.detailLoa(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-list" style={{color: '#e4566e' }}></i></button>  
                        {
                            this.props.dataUser.code_roles == 'RL001' || this.props.dataUser.code_roles == 'RL003' || this.props.dataUser.code_roles == 'RL005' ? 
                            <>
                                                  <button onClick={() => this.editLoa(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none', display: this.props.displayBtn}} ><i className="fa fa-pencil" style={{color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteLoa(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none',display:this.props.displayBtn}} ><i className="fa fa-trash" style={{color: '#e4566e' }}></i></button>
                            </> : ''
                        }              
                    </div>
                ),
                style: {
                    // textAlign: 'center'
                    whiteSpace: 'nowrap'
                },
                // width:250,
                sortable: false,
                filterable: false
            }
        ]
        return (
            <Fragment>
                <div>
                    <div className="card">
                        <div className="card-header bg-red-color">
                            <div className="row">
                                <div className="col-10">
                                    <h5 style={{fontSize:15}}>Schedule Invoice</h5>
                                </div>
                                <div className="col-2">
                                    <button onClick={() => this.addLoa()} className="btn btn-light btn-xs" type="button" style={{marginLeft: 'auto', border:'none',display:this.props.display}} ><i className="fa fa-plus color-red"></i></button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ReactTable
                                        data={data}
                                        columns={columns}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                        showPaginationBottom={false}
                                        style={{
                                            height: "300px"
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderEditDialog()}
                {this.renderDetailDialog()}
                {this.renderDeleteDialog()} 
                {this.renderAddDialogDetail()}
                {this.renderEditDialogDetail()}
                {this.renderDeleteDialogDetail()} 
            </Fragment>
        )
    }
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    dataToken: state.Login.lgnInitialize,
})
  
export default connect(reduxState, null)(withRouter(DataLoa));