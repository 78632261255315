import React, { Fragment, Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import Loader from '../../../src/components/common/loader'
import { matchSorter } from 'match-sorter';
import { ExportCSV } from '../../../src/services/ExportCSV';
import setRangeDate from '../../helper/general-function';

class ScheduleInvoiceStudentGranted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingRow: null,
            deletingRow: null,
            showLoading: false,
            model: {
                nama: "",
                alamat: "",
                id_propinsi: "",
                id_kabkota: "",
                no_hp: "",
                email: "",
                kota_lahir: "",
                tgl_lahir: "",
                jenis_kelamin: "",
                code_jenpen: "",
                code_kelas: "",
                code_school: "",
                code_leads_source: "",
                code_konsultan: "",
                code_ambassador: "",
                code_students_category: "",
                code_kampus_tujuan: "",
                code_sekolah_asal: "",
                code_kampus_asal: "",
                ket_kampus: ""
            },
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            logName: '',
            toastId: null,
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataConsultant: [],
            dataProvinsi: [],
            dataKabKota: [],
            dataKabKotaFilter: [],
            dataJenPen: [],
            dataSekolah: [],
            dataKampus: [],
            dataKelasSemester: [],
            dataKelasSemesterFilter: [],
            dataLeadsSource: [],
            dataAmbassador: [],
            dataRoles: [],
            dataStatus: [],
            dataProgress: [],
            dataCategory: [],
            dataCampus: [],
            filtered: [],
            searchInput: "",
            checkemail: "",
            pesanEmailError: "",
            renderInputSekolah: "none",
            dropdownOpen: false,
            isOpen: false,
            filterDownload: [],
            id_filter: '',
            date: 'all_data',
            startDate: Moment().startOf('year').add(6,'months').format('YYYY-MM-DD'),
            endDate: Moment().endOf('year').add(6,'months').format('YYYY-MM-DD'),
        }
    }

    componentDidMount() {
        this.getAllstudents();
    }

    handleInput(e) {
        this.setState({
            [e.target.name] : e.target.value,
            showLoading: true
        }, () => {
            this.setRangeDate()
        })
    }

    setRangeDate(){ 
        let data = setRangeDate(this.state.date);
        this.setState({ 
            startDate: data.start,
            endDate: data.end
        },() => {
            this.getAllstudents();
        })
    }


    getAllstudents = () => {
        var formData = new FormData();
        formData.append('start_date', this.state.startDate);
        formData.append('end_date', this.state.endDate);

        const codeRoles = this.props.codeRoles
        let url = '/students/progresslog'
        if (codeRoles === 'RL008')
            url = '/ambassador/students-data/' + this.props.dataUser.code_ambassador
        this.setState({ showLoading: true })

        axios.post(API_URL.superStudent + url, formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                data: result,
                showLoading: false
            })
        })
        .catch(error => {
            this.setState({ showLoading: true })
            console.log(error)
        })
    }

    render() {
        const columns = [
            {
                Header: 'Code',
                accessor: 'code_students',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_students"] }), 
                filterAll: true
            },
            {
                Header: 'Member Code',
                accessor: 'code_member',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_member"] }),
                filterAll: true
            },
            {
                Header: 'Name',
                accessor: 'nama',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true
            },
            {
                Header: 'Telp',
                accessor: 'no_hp',
                filterable: true
            },
            {
                Header: 'Progress',
                accessor: 'name_progress',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name_progress"] }),
                filterAll: true
            },
            {
                Header: 'Status',
                accessor: 'name_status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name_status"] }),
                filterAll: true
            },
            {
                Header: 'Tgl Grandted',
                width:150,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    Moment(row.original.date_grandted).format('DD-MMMM-YYYY')
                )
            },
            {
                Header: 'Consultant',
                accessor: 'nama_konsultan',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_konsultan"] }),
                filterAll: true
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div className="row">
                        <div className="col-md-3">
                            <button
                                onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.token_students)}
                                className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-list" style={{ width: 35, fontSize: 16, padding: 2, color: '#e4566e' }}></i>
                            </button>
                        </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width: 100,
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className='row mb-3'>
                            <div className="col-md-12">
                                <div className="text-right">
                                    <ExportCSV csvData={this.state.data} fileName={"data-student-grandted-schedulle-invoice"} />
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-sm-3">
                                <select name='date' value={this.state.date} onChange={(e) => this.handleInput(e)} className='form-control'>
                                    <option value='all_data'>All Time</option>
                                    <option value='this_week'>This Week</option>
                                    <option value='last_week'>Last Week</option>
                                    <option value='this_month'>This Month</option>
                                    <option value='last_month'>Last Month</option>
                                    <option value='this_year'>This Year</option>
                                    <option value='last_year'>Last Year</option>
                                    <optgroup label='Quartal'>
                                        <option value='1'>Quartal I</option>
                                        <option value='2'>Quartal II</option>
                                        <option value='3'>Quartal III</option>
                                        <option value='4'>Quartal IV</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.data.length > 0 ?
                                            <ReactTable
                                                data={this.state.data}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={100}
                                                className="-striped -highlight"
                                             /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};


const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(ScheduleInvoiceStudentGranted));
