/* eslint-disable eqeqeq,  no-useless-concat */

import React, { Fragment, Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import API_URL from '../../../../ApiUrl';
import { matchSorter } from 'match-sorter';

class DetailStudentTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataStudentTask: [],
        }
    }

    componentDidMount() {
        this.getDetailStudentTask();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.code_konsultan !== this.props.code_konsultan) {
            this.getDetailStudentTask();
        }
    }

    handleInput(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    closeDetailLeadsEvent() {
        window.location.reload();
    }

    getDetailStudentTask() {
        let formData = new FormData()
        formData.append('code_konsultan', this.props.code_konsultan)
        formData.append('start_date', this.props.start_date)
        formData.append('end_date', this.props.end_date)
        formData.append('type', 'student_task')
        axios.post(API_URL.superStudent+'/list-data-follow-up', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                dataStudentTask: response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    handleApprove(item) {
        axios.get(API_URL.superStudent + '/student/approval-task/' + item.id + '/edit', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(() => {
            this.getDetailStudentTask();
        })
    }

    render() {

        const column = [
            {
                Header: 'Name',
                accessor: 'student.nama',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["student.nama"] }),
                filterAll: true,
                Cell: (row) => (
                    <span onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.student.token_students)} style={{ border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} >{row.original.student.nama}</span>
                )
            },
            {
                Header: 'Email',
                accessor: 'student.email',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["student.email"] }),
                filterAll: true,
            },
            {
                Header: 'No. Hp.',
                accessor: 'student.no_hp',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["student.no_hp"] }),
                filterAll: true,
                Cell: (row) => (
                    row.original.student.no_hp.substring(0,2) == '62' ?
                    <a href={'https://wa.me/'+row.original.student.no_hp} rel='noreferrer noopener'  target='_blank'>{row.original.student.no_hp}</a>
                    :
                    <a href={'https://wa.me/'+'62'+row.original.student.no_hp.substring(1)} rel='noreferrer noopener' target='_blank'>{row.original.student.no_hp}</a>
                )
            },
            {
                Header: 'Task',
                accessor: 'description',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["description"] }),
                filterAll: true,
            },
            {
                Header: 'Action',
                filterable: false,
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <>
                        <button onClick={() => this.handleApprove(row.original)} className='btn btn-danger btn-xs'><i className='fa fa-check'></i></button>
                    </>
                ),
            },
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className='card-header'>
                                        <div className='row'>
                                            <div className='col-sm-11'>
                                                <h5>Detail Student Task {this.props.nama}</h5>
                                            </div>
                                            <div className='col-sm-1'>
                                                <button className='btn-danger' onClick={() => this.closeDetailLeadsEvent()}><i className='fa fa-close'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <ReactTable
                                            data={this.state.dataStudentTask}
                                            columns={column}
                                            defaultPageSize={20}
                                            className="-striped -highlight"
                                            showPaginationBottom={true}
                                            filterable
                                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(DetailStudentTask))