/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import ReactTable from 'react-table';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { ExportCSV } from '../../../services/ExportCSV';
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import excel from '../../../assets/images/document-logo/excel-logo.png';
import word from '../../../assets/images/document-logo/word-logo.png';
import pdf from '../../../assets/images/document-logo/pdf-logo.png';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { saveAs } from "file-saver";
import Moment from 'moment';

class RegisterEventYouth extends Component {

    constructor(props){
        super(props)
        this.state = {
            registrasi_data: [],
            event_id:0,
            event_data:[],
            listMembers:[],
            listStudents:[],
            detail: [],
            modalDetail: false,
            modalPreview: false,
            files: "",
            showAction: this.props.dataUser.code_roles === 'RL023' ? '' : this.props.dataUser.code_roles === 'RL001' ? '' : this.props.dataUser.code_roles === 'RL003' ? '' :  this.props.dataUser.code_roles === 'RL009' ? '' : 'none',
        }
    }

    componentDidMount(){
        this.getDataEvent()
    }

    getDataRegisterEvent = () => {
        axios.get(API_URL.eventUrl+'load-data/'+this.state.event_id+'/1', {
            headers:{
                'Authorization': `Bearer ${API_URL.eventToken}`,
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ registrasi_data: result}))
        .catch(error => console.log(error))
    }

    getDataEvent = () => {
        const url = API_URL.superStudent+'/event/jenis-event/1';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                event_data:data.data
            });
        });
    }

    getDataRegisterMember = (params) => {
        let formData = new FormData()
        formData.append('id', params.id)
        formData.append('start_date', params.start_date)
        formData.append('end_date', Moment(params.start_date,'YYYY-MM-DD').add('days',7).format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/event/register-members', formData,{
            headers:{ 'Authorization': `Bearer ${this.props.dataToken}`,}
        })
        .then(response => this.setState({listMembers:response.data.data.register}))
        .catch(error => console.log(error))
    }

    getDataRegisterStudent = (params) => {
        let formData = new FormData()
        formData.append('id', params.id)
        formData.append('start_date', params.start_date)
        formData.append('end_date', Moment(params.start_date,'YYYY-MM-DD').add('days',7).format('YYYY-MM-DD'))
        formData.append('code_progress', 'PG004')
        axios.post(API_URL.superStudent+'/event/register-students', formData,{
            headers:{ 'Authorization': `Bearer ${this.props.dataToken}`,}
        })
        .then(response => this.setState({listStudents:response.data.data.register}))
        .catch(error => console.log(error))
    }

    handleChange = (e) => {
        let eventSeleted = this.state.event_data.filter((item) => {
                return item.id == e.target.value
        })
        this.setState({
            [e.target.name]: e.target.value
        },() => {
            this.getDataRegisterEvent()
            this.getDataRegisterMember(eventSeleted[0])
            this.getDataRegisterStudent(eventSeleted[0])
        })
    }

    detailRow(row) {
        this.setState({
            modalDetail: true,
            detail: [
                { 'url': row.kartu, 'file': row.kartu_origin, 'name': 'Kartu Pelajar' },
                { 'url': row.motlet, 'file': row.motlet_origin, 'name': 'Motivation Letter' },
                { 'url': row.foto, 'file': row.foto_origin, 'name': 'Foto Diri' },
                { 'url': row.cv, 'file': row.cv_origin, 'name': 'CV' },
            ]
        })
    }

    filePreview(file) {
        this.setState({
            modalPreview: true,
            files: file
        })
    }

    closeModal() {
        this.setState({
            modalDetail: false,
            modalPreview: false,
        })
    }

    downloadFile = (url, filename) => {
        saveAs(url,filename.substring(filename.lastIndexOf('/')));
    }

    renderDetailDialog() {
        const modal = this.state.modalDetail;

        return (
            <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>Detail Dokumen</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    {
                        this.state.detail.length > 0 ?
                        this.state.detail.map((row)=>(
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <h3>{row.name}</h3>
                                    <div className="media markets">
                                        {
                                            row.file.split('.').pop() == 'pdf' ?
                                            <img className='document-img' style={{maxWidth:"75px"}} alt='images' onClick={() => this.filePreview(row.url)} src={pdf} />
                                            :
                                            row.file.split('.').pop() == 'docx' ?
                                            <img className='document-img' style={{maxWidth:"75px"}} alt='images' onClick={() => this.filePreview(row.url)} src={word} />
                                            :
                                            row.file.split('.').pop() == 'xlsx' ?
                                            <img className='document-img' style={{maxWidth:"75px"}} alt='images' onClick={() => this.filePreview(row.url)} src={excel} />
                                            :
                                            <img className='document-img' style={{maxWidth:"150px"}} alt='images' onClick={() => this.filePreview(row.url)} src={row.url} />
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-9'>
                                    <button onClick={() => this.downloadFile(row.url, row.file)} className="btn btn-outline-success mt-5" type="button"><i className="fa fa-download"></i></button>
                                </div>
                            </div>
                        ))
                        :
                        ''
                    }
                </ModalBody>
            </Modal>
        )
    }

    renderPreview() {
        const modalPreview = this.state.modalPreview;
        const url_doc = [{ uri:this.state.files }]
        const url_download = this.state.files;

        return (
            <Modal className="modal-dialog" isOpen={modalPreview} toggle={() => this.closePreview()} size="lg">
              <ModalHeader toggle={() => this.closePreview()}>Detail Files</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    {
                      this.state.files !== undefined ? 
                        
                        <div>
                            <DocViewer 
                                pluginRenderers={DocViewerRenderers} 
                                documents={url_doc} 
                                config={{
                                    header: {
                                        disableHeader: false,
                                        disableFileName: false,
                                        retainURLParams: false
                                    }
                                }}
                            />
                            <div className="form-row" style={{textAlign: "end"}}>
                                <div className="col-md-12">
                                    <button onClick={() => this.downloadFile(url_download, this.state.files)} className="btn btn-outline-success" type="button"><i className="fa fa-download"></i></button>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
              </ModalBody>
            </Modal>
        )
    }
    
    render(){
        const columns = [
            {
                Header: 'Kode Regristasi',
                accessor: 'register_code',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Nama Lengkap',
                accessor: 'name',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    row.original.code_member == null ? <span>{row.original.name}</span> : <span>({row.original.code_member}) {row.original.name}</span>
                ),
                filterable: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Telepon/WA',
                accessor: 'phone',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Provinsi',
                accessor: 'province_name',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Kabupaten',
                accessor: 'kabupaten_name',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.detailRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-list" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ];

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row m-3">
                                            <div className="col-sm-4">
                                                <div className="text-left">
                                                <h5 className="mb-1">Total Registration : <span className="badge badge-primary"><Countup end={this.state.registrasi_data.length} /></span> </h5>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div style={{display: this.state.showAction}}  className="col-sm-6">
                                                    <ExportCSV csvData={this.state.registrasi_data} fileName={"data-global-youth"} />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="text-right">
                                                    <select className="form-control" onChange={this.handleChange} name="event_id" value={this.state.event_id}>
                                                        <option value='0'> --Filter Peserta-- </option>
                                                        {
                                                        this.state.event_data.map(event => {
                                                            return <option value={event.id} key={event.id}>{event.name}</option>
                                                        })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <ReactTable
                                                    data={this.state.registrasi_data}
                                                    columns={columns}
                                                    defaultPageSize={10}
                                                    className="-striped -highlight"
                                                    showPaginationBottom={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h5>Member</h5>
                                        <ul>
                                            {this.state.listMembers.length > 0 ?
                                                this.state.listMembers.map(member => (
                                                    <li key={member.id}><i className="fa fa-caret-right txt-secondary m-r-10"></i> ({member.code_member}) {member.name}</li>
                                                ))
                                                :
                                                ''
                                            }
                                        </ul>
                                    </div>
                                </div>               
                            </div>
                            <div className='col-md-6'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h5>Student Collect Document</h5>
                                        <ul>
                                            {this.state.listStudents.length > 0 ?
                                                this.state.listStudents.map(student => (
                                                    <li key={student.code_students}><i className="fa fa-caret-right txt-secondary m-r-10"></i> ({student.code_students}) {student.name}</li>
                                                ))
                                                :
                                                ''
                                            }
                                        </ul>
                                    </div>
                                </div>        
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDetailDialog()}
                {this.renderPreview()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
})

export default connect(reduxState, null) (RegisterEventYouth);