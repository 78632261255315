import React, { Fragment, Component } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
// import { Tabs as ReactTabs, TabList as ReactTablist } from 'react-tabs';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import "./styles/react-router-tabs.css";

class SchoolDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listSchool: []
        }
    }

    componentDidMount(){
        this.getDataSchool();
    }

    getDataSchool() {
        const url = API_URL.superStudent+'/school-activities/detail/'+this.props.match.params.codeSchool;
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({listSchool:data.data[0]});
        });
    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <Breadcrumb title="School Detail" parent="School Detail" />
                    <div className='card'>
                        <div className='card-body'>
                            <div className='row mb-5'>
                                <div className='col-lg-12'>
                                    <h3 className='text-center'>{this.state.listSchool.nama_sekolah}</h3>
                                </div>
                            </div>
                            <div className='row m-1'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <h6>School Code</h6>
                                        </div>
                                        <div className='col-lg-1'>
                                            :
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>{this.state.listSchool.kode_sekolah}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <h6>Jenjang Pendidikan</h6>
                                        </div>
                                        <div className='col-lg-1'>
                                            :
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>{this.state.listSchool.jenjang_pendidikan}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row m-1'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <h6>No. Telp</h6>
                                        </div>
                                        <div className='col-lg-1'>
                                            :
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>{this.state.listSchool.notlp_sekolah}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <h6>Propinsi</h6>
                                        </div>
                                        <div className='col-lg-1'>
                                            :
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>{this.state.listSchool.nama_propinsi}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row m-1 mt-5'>
                                <div className='col-lg-12'>
                                    <h6>Alamat : {this.state.listSchool.alamat_sekolah}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-1">
                        <div className="col-md-12">
                            <RoutedTabs tabClassName="tab-link" activeTabClassName="active">
                                <div className="tabs tab-title">
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/`+this.props.match.params.codeSchool+`/activities`}>
                                        School Activities
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/`+this.props.match.params.codeSchool+`/feedback`}>
                                        School Feedback
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/`+this.props.match.params.codeSchool+`/schedulle`}>
                                        School Schedule
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/`+this.props.match.params.codeSchool+`/granted`}>
                                        School Granted
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/`+this.props.match.params.codeSchool+`/member`}>
                                        School Member
                                    </NavTab>
                                </div>
                            </RoutedTabs>
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/schooldetail/`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/schooldetail/activities/`+this.props.match.params.codeSchool} />}
                                />
                            </Switch>
                        </div>
                        <hr/>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})
  
export default connect(reduxState, null)(withRouter(SchoolDetail));