/* eslint-disable array-callback-return */

import React, { Fragment, Component } from 'react';
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Chart from '../../components/dashboard/partition/component/chart'
import { UncontrolledTooltip } from 'reactstrap';

class DataAdmission extends Component{

    constructor(props){
        super(props)
        this.state = {
            dataStudents: [],
            listStudents:[],
            dataWillAdmission:[],
            filterStudents: [],
            listConsultant:[],
            listProgress:[],
            documentRequired: [],
            listFile:[],
            dataGraphic:[],
            todoList:[],
            listNotes:[],
            noted:'',
            status:'',
            id_todo:'',
            id_note:'',
            filterConsultant:'',
            code_students:'',
            filterProgress:'',
            filterStatus:'',
            description:'',
            btnAddText: 'Add',
            action:'',
            statusAdmission:'',
            dateType: 'this_week',
            startDate: Moment().startOf('isoWeek'),
            endDate: Moment().endOf('isoWeek'),
            btnAddDisabled:false,
            modal_update: false,
            modal_document: false,
            modall_start_study:false,
            modal_todo:false,
            modal_note:false,
            valueWeek:Moment().year()+'-W'+Moment().isoWeek(),
            styleShow: '',
        }
    }

    componentDidMount(){
        this.loadStudentsAdmission()
        this.loadGraphic()
        this.loadStudentsData()
        this.loadConsultants()
        this.loadProgress()
        this.loadTodoList()
        this.loadNotes()
    }

    setRangeDate(){   
        let startDate = Moment();
        let endDate  = Moment();     
        switch(this.state.dateType){
            case 'last_week':
                startDate.subtract(1, 'weeks').startOf('isoWeek')
                endDate.subtract(1, 'weeks').endOf('isoWeek')
                break;
            case 'this_month':
                startDate.startOf('month')
                endDate.endOf('month')
                break;
            case 'last_month':
                startDate.subtract(1, 'months').startOf('month')
                endDate.subtract(1, 'months').endOf('month')
                break;
            case 'this_year':
                startDate.startOf('year').add(-6,'months')
                endDate.endOf('year').add(-6,'months')
                break;
            case 'last_year':
                startDate.subtract(1, 'year').startOf('year').add(-6,'months')
                endDate.subtract(1, 'year').endOf('year').add(-6,'months')
                break;
            default:
                startDate.startOf('isoWeek')
                endDate.endOf('isoWeek')
        } 
        this.setState({ 
            startDate,
            endDate
        },() => this.filter())
    }

    filter = () => {
        this.loadStudentsAdmission()
        this.loadGraphic()
        this.loadTodoList()
    }

    loadStudentsAdmission = () => {
        let filterData = new FormData();
        filterData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        filterData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/student/admission-data', filterData,{
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({dataStudents: result}))
        .catch(error => console.log(error))
    }

    loadGraphic = () => {
        let filterData = new FormData();
        filterData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        filterData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/progress/count-student-progress', filterData,{
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({dataGraphic:result}))
        .catch(error => console.log(error))
    }

    loadStudentsData = () => {
        axios.get(API_URL.superStudent+'/student/will-admission',{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({dataWillAdmission:result}))
        .catch(error => console.log(error))
    }

    loadListStudentsData = () => {
        axios.get(API_URL.superStudent+'/student/list-students',{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({listStudents:result}))
        .catch(error => console.log(error))
    }

    loadTodoList = () =>{
        let formData = new FormData ()
        formData.append('code_jobdesk','JD006')
        formData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/todo/list-todo', formData, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({todoList: result}))
        .catch(error => console.log(error))
    }

    loadConsultants = () => {
        axios.get(API_URL.superStudent+'/consultant/list-all',{
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({listConsultant: result}))
        .catch(error => console.log(error))
    }

    loadProgress = () => {
        axios.get(API_URL.superStudent+'/master/progress',{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({listProgress: result}))
        .catch(error => console.log(error))
    }

    loadNotes = () => {
        let formData = new FormData()
        formData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        formData.append('end_date',this.state.endDate.format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/note-by-date',formData,{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({listNotes: result}))
        .catch(error => console.log(error))
    }

    handleWeek = (e) => {
        this.setState({
            startDate: Moment(e.target.value).startOf('isoWeek'),
            endDate: Moment(e.target.value).endOf('isoWeek'),
            valueWeek:e.target.value
        },() => {
            this.loadNotes()
        })
    }

    setFilterConsultant = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterConsultant = newData; 
          return { filterConsultant };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"consultant_name");
          })
    }

    setFIlterProgress = (e) => {
        const newData = e.target.value
        this.setState(prevState => {
            let filterProgress =newData;
            return {filterProgress}
        }, () => {
            this.onFilteredChangeCustom(newData,"progress_name")
        })
    }

    setFilterStatus = (e) => {
        const newData = e.target.value
        this.setState(prevState => {
            let filterStatus = newData
            return { filterStatus }
        },() => {
            this.onFilteredChangeCustom(newData, "status_admission")
        } )
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filterStudents;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filterStudents: filtered });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleDate = (e) => {
        this.setState({
            dateType: e.target.value
        }, () => this.setRangeDate())
    }
    
    setStudent = (e) => {
        const newData = e.length > 0 ? e[0].code_students : "";
        this.setState(prevState => {
          let code_students = Object.assign({}, prevState.code_students);  
          code_students = newData; 
          return { code_students };                   
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({
            btnAddDisabled:true,
            btnAddText:'Adding..'
        })
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('code_progress','PG006')
        formData.append('code_status','ST002')
        axios.post(API_URL.superStudent+'/student/add-admission-data', formData,{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            this.setState({
                code_students:'',
                btnAddDisabled:false,
                btnAddText:'Add'
            })
            this.filter()
            this.loadStudentsData()
            toast.success("student data added successfully.");
        })
        .catch(error => {
            this.setState({
                btnAddDisabled:false,
                btnAddText:'Add'
            })
            toast.error("there is an error");
        })
    }

    handleUpdate = (e) => {
        this.setState({
            modal_update:true,
            code_students: e.code_students,
            statusAdmission: e.status_admission,
            keteranganAdmission: e.ket_admission
        })
    }

    handleProgress = (e) => {
        let progress = e.code_progress
        let document = '';
        switch (progress) {
            case 'PG006':
                document = [
                    {
                        'code_document':'DT023',
                        'name': 'LoA & School Invoice'
                    },
                    {
                        'code_document':'DT019',
                        'name': 'Simulasi Rincian Biaya'
                    }
                ]
                break;
            case 'PG009':
                document = [
                    {
                        'code_document':'DT030',
                        'name': 'Bukti Visa Lodgment'
                    }
                ]
                break;
            case 'PG010' :
                document =[
                    {
                        'code_document':'DT031' ,
                        'name' : 'Dokumen Visa Granted'
                    },
                    {
                        'code_document':'DT035' ,
                        'name' : 'Dokumen Visa Rejection'
                    }
                ]
                break;
            case 'PG015' :
                document = [
                    {
                        'code_document': '',
                        'name': 'Strat Study'
                    }
                ]
                break;
            case 'PG016' :
                document = [
                    {
                        'code_document' :'DT040',
                        'name' : 'Visa Extend'
                    },
                    {
                        'code_document' :'DT041',
                        'name' : 'Document Student Extend'
                    }
                ]
                break;
            case 'PG019' :
                document = [
                    {
                        'code_document' :'DT042',
                        'name' : 'Graduate Visa Document'
                    }
                ]
                break;
            default:
                break;
        }

        this.setState({
            documentRequired: document,
            code_students: e.code_students,
            modal_document: progress === 'PG015' ? false : true,
            modall_start_study: progress === 'PG015'? true : false,
            listFile:[]
        },() => {
            document.map(() => {
                this.setState(prevState => ({
                    listFile:[
                        ...prevState.listFile,
                        {
                            code_document:'',
                            file:''
                        }
                    ]
                }))
            })
        })
    }

    saveUpdate = (e) => {
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('status_admission', this.state.statusAdmission)
        formData.append('keterangan_admission', this.state.keteranganAdmission)
        axios.post(API_URL.superStudent+'/student/update-admission-data',formData,{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            this.loadStudentsAdmission()
            this.setState({
                modal_update:false
            })
            toast.success("student data update successfully.");
        })
        .catch(error => {
            toast.error("there is an error");
        })
    }

    setFiles = (e, index, code_document) => {
        let _listFile = [...this.state.listFile]
        _listFile[index].code_document = code_document
        _listFile[index].file = e.target.files[0]
        _listFile.push()
    }

    startStudey(){
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('code_progress','PG016')
        formData.append('code_status','ST007')
        axios.post(API_URL.superStudent+'/student/update-progress-status', formData, {
                headers:{
                    Authorization: `Bearer ${this.props.dataToken}`
                }
            })
        .then(response => {
            this.setState({
                btnAddText:'Add',
                btnAddDisabled:false,
                modall_start_study:false
            })
            this.filter()
            toast.success("status students updated successfully.");
        })
        .catch(error => {
            this.setState({
                btnAddText:'Add',
                btnAddDisabled:false,
                modall_start_study:false
            })
        toast.error("error when updated student status.");
        })
    }

     async saveProgram(){
        this.setState({
            btnAddText:'Adding...',
            btnAddDisabled:true
        })
        let files = this.state.listFile.filter(function(item){
            return item.code_document !== '';
        })
        console.log(files, this.state.list)
        files.map( async (item,index) => {
            let formData = new FormData()
            formData.append('code_students', this.state.code_students)
            formData.append('code_document',item.code_document)
            formData.append('file',item.file)
            await axios.post(API_URL.superStudent+'/students-document/upload-document', formData, {
                headers:{
                    Authorization: `Bearer ${this.props.dataToken}`
                }
            })
            .then(response => {
                this.setState({
                    btnAddText:'Add',
                    btnAddDisabled:false,
                    modal_document:false
                })
                this.filter()
                toast.success("document "+response.data.data+" uploaded successfully.");
            })
            .catch(error => {
                this.setState({
                    btnAddText:'Add',
                    btnAddDisabled:false,
                    modal_document:false
                })
                toast.error("document failed to upload.");
            })
        })
    }

    renderModalUpdate(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_update} toggle={() => this.setState({ modal_update: false })}>
                <ModalHeader toggle={() => this.setState({ modal_update: false })}>Update Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='control-label'>Status</label>
                                <select className='form-control' value={this.state.statusAdmission} name='statusAdmission' onChange={this.handleInput}>
                                    <option key={0} value=''> --Pilih Status-- </option>
                                    <option key={1} value='1'> Urgent </option>
                                    <option key={2} value='0'>Non-Urgent </option>
                                    <option key={3} value='3'>Accelerate </option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Keterangan</label>
                                <textarea className='form-control' onChange={this.handleInput} name='keteranganAdmission' value={this.state.keteranganAdmission} />
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={(e) => this.saveUpdate()}>Save</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_update: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    updateTask = (row) => {
        this.setState({
            id_todo: row.id,
            noted: row.noted,
            status: row.status,
            modal_todo:true
        })
    }

    finish(){
        let formData = new FormData()
        formData.append('id_todo', this.state.id_todo)
        formData.append('noted', this.state.noted)
        formData.append('status', this.state.status)
        axios.post(API_URL.superStudent+'/todo/finish-todo', formData,{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            toast.success('Task list updated successfully')
            this.setState({
                modal_todo:false
            })
            this.loadTodoList()
        })
        .catch(error => {
            toast.error('there is an error')
        })  
    }

    renderModalDocument(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_document} toggle={() => this.setState({ modal_document: false })}>
                <ModalHeader toggle={() => this.setState({ modal_document: false })}>Required Documents</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            {
                                this.state.documentRequired.length > 0 ?
                                this.state.documentRequired.map((item, index) => (
                                    <div key={index} className='form-group'>
                                        <label className='control-label'>{item.name}</label>
                                        <input onChange={(e) => this.setFiles(e, index, item.code_document)} className='form-control' type='file' name='file[]'/>
                                    </div>
                                ))
                                :
                                ''
                            }
                            
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.saveProgram()}>{this.state.btnAddText}</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_document: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    renderModalStratStudy(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modall_start_study} toggle={() => this.setState({ modall_start_study: false })}>
                <ModalHeader toggle={() => this.setState({ modall_start_study: false })}>Update Progress</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            Add this students to Start Study ?
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.startStudey()}>{this.state.btnAddText}</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modall_start_study: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    renderModalTodo(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_todo} toggle={() => this.setState({ modal_todo: false })}>
                <ModalHeader toggle={() => this.setState({ modal_todo: false })}>Update Progress</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-md-12'>
                            <form>
                                <div className='form-group'>
                                    <label className='control-label'>Noted</label>
                                    <textarea className='form-control' onChange={this.handleInput} value={this.state.noted} name='noted'></textarea>
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Status</label>
                                    <select name='status' value={this.state.status} className='form-control' onChange={this.handleInput}>
                                        <option key={0} value='0'>Select Status</option>
                                        <option key={1} value='1'>Progress</option>
                                        <option key={3} value='3'>Done</option>
                                        <option key={4} value='2'>Pending</option>
                                        <option key={5} value='7'>Revisi</option>
                                        <option key={6} value='5'>Cancel</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.finish()}>Yes</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_todo: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    addModalNote = () => {
        this.loadListStudentsData()
        this.setState({
            modal_note :true,
            styleShow:'',
            action:'add',
            description:'',
            code_students:''
        })
    }

    saveNote = () => {
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('description', this.state.description)
        let url = '/notes'
        if (this.state.action === 'edit'){
            formData.append('_method','PATCH');
            url = '/notes/'+this.state.id_note
        }
        axios({
            method:'POST',
            url: API_URL.superStudent+url,
            data: formData,
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            this.setState({
                modal_note:false,
                code_students:''
            })
            this.loadNotes()
            toast.success("successfully add data");
        })
        .catch(error => {
            this.setState({
                modal_note:false,
                code_students:''
            })
            toast.error("there is an error");
        })
    }

    updateNote = (row) => {
        this.setState({
            modal_note:true,
            styleShow: 'none',
            action:'edit',
            description:row.description,
            id_note: row.id
        })
    }

    deleteNote = (row) => {
        axios.delete(API_URL.superStudent+'/notes/'+row.id,{
            headers: { Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.loadNotes()
            toast.success("successfully deleted data");
        })
        .catch(error => {
            toast.error("there is an error");
        })
    }

    renderModalNote(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_note} toggle={() => this.setState({ modal_note: false })}>
                <ModalHeader toggle={() => this.setState({ modal_note: false })}>Notes</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-md-12'>
                             <div style={{display:this.state.styleShow}} className='form-group'>
                                <Typeahead 
                                    className='mr-2'
                                    id="basic-typeahead2"
                                    labelKey="nama"
                                    name="code_students"  
                                    value={this.state.code_students}
                                    options={this.state.listStudents}
                                    onChange={this.setStudent}
                                    placeholder="--List Students--"
                                />
                             </div>
                             <div className='form-group'>
                                <textarea className='form-control' rows={10} name='description' value={this.state.description} onChange={this.handleInput} />
                             </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.saveNote()}>Save</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_note: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){

        const columns = [
            {
                Header: 'Code',
                accessor: 'code_students',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["code_students"] }),
                style: {textAlign: 'center'},
                filterAll: true,
                Cell:(row) =>(
                    <button style={{border:'none'}} className='btn btn-outline-primary btn-sm btn-pill text-center' onClick={() => 
                        window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.student.token_students)    
                    }>{row.original.code_students}</button>
                )
            },
            {
                Header: 'Nama',
                accessor: 'nama',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["nama"] }),
                style: {textAlign: 'center'},
                filterAll: true
            },
            {
                Header: 'Consultant',
                accessor: 'consultant_name',
                style: {textAlign: 'center'},
                filterable: true,
                Filter:() => 
                    <select
                        onChange={this.setFilterConsultant}
                        style={{ width: '100%' }}
                        value={this.state.filterConsultant || ""}
                        >
                        <option key={0} value=''>Show All</option>
                            {
                            this.state.listConsultant.map( item => {
                                return <option key={item.id} value={item.name}>{item.name}</option>
                            })
                            }   
                </select>,
            },
            {
                Header: 'Progress',
                accessor: 'progress_name',
                style: {textAlign: 'center'},
                filterable: true,
                Filter:() => 
                <select
                    onChange={this.setFIlterProgress}
                    style={{ width: '100%' }}
                    value={this.state.filterProgress || ""}
                    >
                    <option key={0} value=''>Show All</option>
                        {
                        this.state.listProgress.map( item => {
                            return <option key={item.code} value={item.name}>{item.name}</option>
                        })
                        }   
                </select>,
            },
            {
                id: 'input_date',
                Header: 'Tanggal',
                accessor: a => {
                    return Moment(a.input_date)
                    .local()
                    .format("DD/MM/YYYY")
                }
            },
            {
                Header: 'Status',
                accessor: 'status_admission',
                style: {textAlign: 'center'},
                filterable: true,
                Cell: (row) => (
                  row.original.status_admission === '1' ? <span className="badge badge-danger">Urgent</span> : row.original.status_admission === '2' ? <span className="badge bg-warning">Follow Up</span> : row.original.status_admission === '3' ? <span className="badge bg-success">Accelerate</span> :<span className="badge bg-primary">Non-Urgent</span>
                ),
                Filter:() => 
                <select
                    onChange={this.setFilterStatus}
                    style={{ width: '100%' }}
                    value={this.state.filterStatus || ""}
                    >
                    <option key={0} value=''>Show All</option>
                    <option key={1} value='1'>Urgent</option>
                    <option key={2} value='0'>Non-urgent</option>
                    <option key={3} value='2'>Follow Up</option>
                    <option key={4} value='3'>Accelerate</option>
                </select>,
            },
            {
                Header: 'Keterangan',
                accessor: 'ket_admission',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'PIC',
                accessor: 'users.username',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                      <button
                        onClick={()=> this.handleUpdate(row.original)} 
                          className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-edit fa-2x"></i>
                        </button>
                        <button
                        onClick={() => this.handleProgress(row.original)} 
                          className="btn btn-outline-warning btn-xs" type="button" style={{border:'none', display:row.original.code_progress ==='PG018' ? 'none' : row.original.code_progress === 'PG017' ? 'none' : row.original.code_progress === 'PG008' ? 'none' : row.original.code_progress === 'PG020' ? 'none' : ''}} ><i className="fa fa-level-up fa-2x"></i>
                        </button>
                    </>
                ),
                style: {textAlign: 'center'},
                sortable: false,
                filterable: false
            },
        ]

        const columns_todo = [
            {
                Header: '',
                accessor: 'created_at',
                style:{textAlign:'center'},
                width:90,
                Cell:(row) => (
                    <>
                        <span>{Moment(row.original.created_at).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.created_at).format('HH:mm')}</p>
                    </>
                )
            },
            {
                Header: 'Code',
                accessor: 'student.code_students',
                width:100,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <div>                     
                        <button id="UncontrolledTooltipExample" style={{border:'none'}} className='btn btn-outline-primary btn-sm btn-pill text-center' onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.student.token_students)}>{row.original.code_students}</button>
                    </div>
                ),
            },
            {
                Header: 'Students',
                accessor: 'student.nama',
                width:150,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell:(row) => (
                    <>
                        <span id={'code'+row.original.id} data-placement="top">{row.original.description}</span>
                        <UncontrolledTooltip placement="left" target={'code'+row.original.id}>{row.original.description}</UncontrolledTooltip>
                    </>
                ),
                style: {textAlign: 'center'},
            },
            {
                Header: 'Type',
                accessor: 'category.name',
                width:160,
                style: {textAlign: 'center'}
            },
            {
                Header: 'User',
                width:90,
                accessor: 'user.username',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Status',
                accessor: 'status',
                width:70,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <> <span id={`code_status`+row.original.id} className={'badge bg-'+row.original.status.label}>{row.original.status.name}</span> <UncontrolledTooltip placement="top" target={`code_status`+row.original.id}>
                        {row.original.noted}
                  </UncontrolledTooltip> </>
                  ),
            },
            {
                Header: 'University',
                accessor: 'code_university',
                style: {textAlign: 'center'},
                Cell:(row) => (
                   <>{row.original.code_university !== null ? row.original.university.nama_sekolah : ''}</> 
                )
            },
            {
                Header: 'Finish',
                accessor: 'date_finish',
                style:{textAlign:'center'},
                width:90,
                Cell:(row) => (
                    row.original.date_finish !== null ? 
                    <><span>{Moment(row.original.date_finish).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.date_finish).format('HH:mm')}</p></> : ''
                )
            },
            {
                Header: '',
                accessor: 'action',
                width:50,
                Cell: (row) => (
                    row.original.status_id === 3 ? '' : row.original.status_id === 5 ? '' : <button onClick={() => this.updateTask(row.original)} style={{border:'none'}} className='btn btn-outline-success btn-xs'><i style={{fontSize:16}} className='fa fa-pencil '></i></button>
                  ),
                style: {textAlign: 'center'},
            },
        ]
        
        const columns_notes = [
            {
                Header: '',
                accessor: 'created',
                width:85,
                Cell:(row) => (
                    <>
                        <span className='text-center'>{Moment(row.original.created_at).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.created_at).format('HH:mm')}</p>
                    </>
                )
            },
            {
                Header: 'Nama',
                accessor : 'student.nama',
                width:150
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: '',
                accessor: 'action',
                width:80,
                Cell: (row) => (
                     <>
                     <button onClick={() => this.updateNote(row.original)} style={{border:'none'}} className='btn btn-outline-success btn-xs'><i style={{fontSize:16}} className='fa fa-pencil '></i></button>
                     <button onClick={() => this.deleteNote(row.original)} style={{border:'none'}} className='btn btn-outline-danger btn-xs'><i style={{fontSize:16}} className='fa fa-trash '></i></button>
                     </>
                  ),
                style: {textAlign: 'center'},
            },
        ]
        return(
            <Fragment>
                <div className='card'>
                    <div className='card-header'>
                        <div className='row justify-content-md-center'>
                            <div className='col-md-6'>
                                <form onSubmit={this.handleSubmit} className="form-inline">
                                    <Typeahead 
                                        className='mr-2'
                                        id="basic-typeahead"
                                        labelKey="nama"
                                        name="code_students"  
                                        value={this.state.code_students}
                                        options={this.state.dataWillAdmission}
                                        onChange={this.setStudent}
                                        placeholder="--List Students--"
                                    />
                                    <button disabled={this.state.btnAddDisabled}  type="submit" className="btn btn-primary mr-2">{this.state.btnAddText}</button>
                                    </form>
                            </div>
                            <div className='col-md-3'>
                            <select name='dateType' value={this.state.dateType} onChange={this.handleDate} className='form-control'>
                                <option value='this_week'>This Week</option>
                                <option value='last_week'>Last Week</option>
                                <option value='this_month'>This Month</option>
                                <option value='last_month'>Last Month</option>
                                <option value='this_year'>This Year</option>
                                <option value='last_year'>Last Year</option>
                            </select>
                            </div>
                        </div> 
                    </div>
                    <div className='card-body'>
                        <div className='row justify-content-center'>
                            <div className='col-md-8 mb-5'>
                                <Chart grafik={this.state.dataGraphic} />
                            </div>
                            <div className='col-md-12'>
                                <ReactTable
                                    data={this.state.dataStudents}
                                    filtered = {this.state.filterStudents}
                                    columns={columns}
                                    filterable
                                    onFilteredChange={(filtered, column, value) => {
                                        this.onFilteredChangeCustom(value, column.id || column.accessor);
                                    }}      
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                />
                            </div>
                        {this.renderModalTodo()}
                        {this.renderModalUpdate()}
                        {this.renderModalStratStudy()}
                        {this.renderModalDocument()}
                            <div className='col-md-12 mt-4'>
                                <h4>Task List Admission</h4>
                                <ReactTable
                                    data={this.state.todoList}
                                    columns={columns_todo}   
                                    defaultPageSize={15}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                />
                            </div>
                            <div className='col-md-4 mt-4'>
                                <h4>Noted</h4>                                
                            </div>
                            <div className='col-md-4 mt-4'>
                                <input type='week' value={this.state.valueWeek} onChange={this.handleWeek} className='form-control'/>
                            </div>
                            <div className='col-md-4 mt-4'>
                                <button onClick={() => this.addModalNote()} className='btn btn-success pull-right'>Add Data</button>
                            </div>
                            <div className='col-md-12 mt-2'>
                                <ReactTable
                                    data={this.state.listNotes}
                                    columns={columns_notes}   
                                    defaultPageSize={15}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                />
                            </div>     
                        </div>
                    </div>           
                </div>
                {this.renderModalNote()}
                <ToastContainer />
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize
  })

export default connect(reduxState, null) (DataAdmission);