import React, { Component } from 'react'
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { saveAs } from "file-saver";
import Moment from 'moment';

export class DataRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            row: [],
            filtered:[],
            data_register:[],
        }
    }

    componentDidMount(){
        this.getDataRegister();
    }

    getDataRegister(){
        const url = API_URL.eventUrl + 'arnantyoscholarship';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.eventToken}`
            }
        })
        .then(res => this.setState({
            data_register: res.data
        }))
        // .then(res => console.log(res.data)) 
        .catch(err => console.log(err))
    }

  onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
  }

    downloadFile = (e, url) => {
    saveAs(url,url.substring(url.lastIndexOf('/')));
  }

  render() {
    const data_register = [
        {
            Header: 'Nama',
            accessor: 'nama',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["nama"] }),
            filterAll: true,
            style: {textAlign: 'center'}
        },
        {
            Header: 'Email',
            accessor: 'email',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["email"] }),
            filterAll: true,
            style: {textAlign: 'center'},
        },
        {
            Header: 'Telepon',
            accessor: 'notelp',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["notelp"] }),
            filterAll: true,
            style: {textAlign: 'center'},
        },
        {
            Header: 'Sekolah',
            accessor: 'sekolah',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["sekolah"] }),
            filterAll: true,
            style: {textAlign: 'center'},
        },
        {
            Header: 'Instagram',
            accessor: 'username_ig',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["username_ig"] }),
            filterAll: true,
            style: {textAlign: 'center'},
            Cell: (row) => (<>{row.original.username_ig}</>)
        },
        // {
        //     Header: <b>Bukti Pembayaran</b>,
        //     id: 'action',
        //     accessor: str => "action",
        //     width:90,
        //     filterable:false,
        //     Cell: (row) => (
        //         <>
        //             <button onClick={(e)=>this.downloadFile(e, row.original.bukti_pembayaran)} class="btn btn-outline-warning btn-xs" type="button" style={{border:'none', fontSize: 15}}><i className="fa fa-download"></i></button>
        //         </>
        //     ),
        //     style: { textAlign: 'center' }
        // },
        {
            Header: 'Date Registration',
            Cell: (row) => (
                <div>
                    { Moment(row.original.created_at).local().format("DD-MMM-YYYY") }
                </div>
            ),
            sortable: false,
            filterable: false
        },
        {
            Header: <b>Berkas</b>,
            id: 'action',
            accessor: str => "action",
            width:90,
            filterable:false,
            Cell: (row) => (
                <>
                    <button onClick={(e)=>this.downloadFile(e, row.original.berkas)} class="btn btn-outline-warning btn-xs" type="button" style={{border:'none', fontSize: 15}}><i className="fa fa-download"></i></button>
                </>
            ),
            style: { textAlign: 'center' }
        }

    ]
    return (
        <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                    <ReactTable
                        data={this.state.data_register}
                        columns={data_register}
                        filtered={this.state.filtered}
                        defaultPageSize={15}
                        filterable  
                        className="-striped -highlight"
                        showPaginationBottom={true}
                        onFilteredChange={(filtered, column, value) => {
                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                        }}
                    /> 
                    </div>
                </div>
            </div>
        </div>
        </>
    )
  }
}


export default DataRegister;