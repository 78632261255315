/* eslint-disable eqeqeq, no-unused-vars */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import pdf from '../../../assets/images/document-logo/pdf-logo.png';
import { saveAs } from "file-saver";

class DatatableDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            listHistoryInvoice: [],
            editingRow: null,
            deletingRow: null,
            model: {
                id_students_invoice:"",
                code_student_loa:"",
                code_invoice:"",
                invoice_name: "",
                due_date: "",
                invoice_amount: "",
                status_payment: ""
            },
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            operation: '',
            toastId: null,
            modal:false,
            modalDelete:false,
            modalHistory:false,
            modalPreview:false,
            myData: this.props.myData,
            btnDisabled: false,
            logName: '',
            selectedDocument:'',
            file_doc: '',
            file_url: ''
        }
    }

    componentDidMount(){
        this.setState({
            data: this.props.myData
        });
    }

    resetState(){
        this.setState({
        editingRow: null,
        deletingRow: null,
        model: {
            code_student_loa:"",
            code_invoice:"",
            invoice_name: "",
            due_date: "",
            invoice_amount: "",
            status_payment: "",
            note_payment: "",
        },
        errors: {},
        });
    }

    // handle on change   
    onChangeStatusPay = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.status_payment = newData; 
        return { model };                   
        }, () => {
            this.validateStatusPay();
        })
    }
    onChangeNotePayment = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.note_payment = newData; 
        return { model };                   
        }, () => {
            this.validateStatusPay();
        })
    }
    onChangeFileDoc = (e) => {
        this.setState({
            file_doc:e.target.files[0]
        })
    }
    // end handle on change

    // validasi submit form
    handleValidation(){
        let formIsValid = true;
        //nama document
        if(!this.state.errors.status_payment) {
            formIsValid = false;
        }
        return formIsValid;
    }
    // validasi submit form

    // handle validation per field
    validateStatusPay = () => {
        const data = this.state.model;
        if(data.status_payment.length < 3){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.inputStatusPay = 'Status Payment is required'; 
                errors.inputStatusPay = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.inputStatusPay = '';    
                errors.inputStatusPay = true;                             
                return { errors };                   
            })
        }
    }
    // end handle validation per field

    editRow(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            title: 'Edit Status Payment',
            operation: 'edit',
            modal: true,
            logName: 'EDIT-STATUS-PAYMENT'
        });
    }

    historyRow(row) {
        const url = API_URL.superStudent+'/student-invoice/invoice/history/'+row.code_invoice;
        axios.get(url, { 
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        }).then(response => response.data).then((data) => {
            this.setState({ 
                listHistoryInvoice: data[0].history,
                modalHistory: true
            })
        });
    }

    filePreview(list) {
        this.setState({
            modalPreview: true,
            file_url: list
        })
    }

    saveRow(){
        this.sendData();
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });
        let row = Object.assign({}, this.state.editingRow, this.state.model);
        let data = this.state.data.slice();
        let index = data.indexOf(this.state.editingRow);
        data.splice(index, 1, row);

        var formData = new FormData();
        formData.append('id_students_invoice', data[0].id_students_invoice);
        formData.append('code_student_loa', data[0].code_student_loa);
        formData.append('code_invoice', data[0].code_invoice);
        formData.append('status_payment', data[0].status_payment);
        formData.append('note_payment', data[0].note_payment); 
        formData.append('code_user', this.props.detailUser.code_konsultan); 
        formData.append('file_doc', this.state.file_doc); 

        axios.post(API_URL.superStudent+'/student-invoice/invoice/updatestatus', formData, { 
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}` 
            },
        }).then((reply)=>{
            if(reply.data.code === 200){
                toast.update(this.state.toastId, {
                    render: "Input Success !",
                    type: toast.TYPE.SUCCESS,
                    autoClose: true,
                    className: 'rotateY animated'
                })
                window.location.reload(false);
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false,
            modalHistory: false
        });
        this.resetState();
    }

    closePreview() {
        this.setState({
            modalPreview: false
        })
    }

    downloadFile = (e, url, filename) => {
        saveAs(url,'invoice'+filename.substring(filename.lastIndexOf('/')));
    }

    renderDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modal;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status Payment</label>
                                    <select className="form-control" name="status_payment" type="text" id="status_payment"  value={row.status_payment}
                                        onChange={(e) => this.onChangeStatusPay(e)}
                                        onBlur={(e) => this.validateStatusPay}
                                        ref={e => this.inputStatusPay = e}
                                    >
                                        <option value="0">New</option>
                                        <option value="4">Sent</option>
                                        <option value="5">Outstanding</option>
                                        <option value="1">Paid</option>
                                        <option value="2">Pending</option>
                                        <option value="3">Cancel</option>
                                    </select>
                                    <span>{this.state.errors.inputStatusPay}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            { this.state.model.status_payment == 0 || this.state.model.status_payment == 1 || this.state.model.status_payment == 2 || this.state.model.status_payment == 3 || this.state.model.status_payment == 4 ? 
                                <div>
                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                            {
                                                this.state.model.status_payment == 4 ? 
                                                <label>Invoice Number</label>
                                                :
                                                this.state.model.status_payment == 2 ?
                                                <label>Comment</label>
                                                :
                                                this.state.model.status_payment == 1 ?
                                                <label>Doc. Remmitance</label>
                                                :
                                                <label>Note</label>
                                            }
                                            {
                                                this.state.model.status_payment == 1 ? 
                                                <input className='form-control' type='file' name='file_doc' onChange={(e) => this.onChangeFileDoc(e)} />
                                                :
                                                <textarea className="form-control" rows="3" value={row.note_payment}
                                                    onChange={(e) => this.onChangeNotePayment(e)}>
                                                </textarea>
                                            }
                                            <span>{this.state.errors.note_payment}</span>
                                            <div className="valid-feedback">Looks good!</div>
                                        </div>
                                    </div>
                                </div>
                                
                            : "" }
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDialogHistory() {
        const { title } = 'History Invoice';
        const modal1 = this.state.modalHistory;

        const coloums = [
            {
                Header: 'Kode Invoice',
                accessor: 'code_invoice'
            },
            {
                Header: 'Update By',
                accessor: 'consultant.nama_lengkap'
            },
            {
                Header: 'Image',
                accessor: 'note',
                Cell: (row) => (
                    <div>
                        {
                            row.original.status_payment == '1' ?
                            <div>
                                {
                                    row.original.note != null ?
                                    <div>
                                        {
                                            row.original.note.split('.').pop() == 'pdf' ?
                                            <img style={{maxWidth:"91px"}} className='document-img' alt='images' onClick={() => this.filePreview(row.original.note)} src={pdf} />
                                            :
                                            <img style={{maxWidth:"91px"}} className='document-img' alt='images' onClick={() => this.filePreview(row.original.note)} src={row.original.note} />
                                        }
                                    </div>
                                    :
                                    ''
                                }
                            </div>
                            :
                            <p>{row.original.note}</p>
                        }
                    </div>
                )
            },
            {
                Header: 'Status Pembayaran',
                Cell: (row) => (
                    <div>
                        {
                            row.original.status_payment == '1' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'green', color: 'white'}}>Paid</span>
                            </>
                            :
                            row.original.status_payment == '2' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'rgb(255, 152, 0)', color: 'white'}}>Pending</span>
                            </>
                            :
                            row.original.status_payment == '3' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'rgb(242, 60, 6)', color: 'white'}}>Cancel</span>
                            </>
                            :
                            row.original.status_payment == '4' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'rgb(0, 91, 159)', color: 'white'}}>Sent</span>
                            </>
                            :
                            row.original.status_payment == '5' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'rgb(255, 214, 0)', color: 'white'}}>Outstanding</span>
                            </>
                            :
                            <span className={'badge'} style={{backgroundColor: 'rgb(99, 204, 255)', color: 'white'}}>New</span>
                        }
                    </div>
                )
            },
            {
                Header: 'Tanggal',
                accessor: 'consultant.nama_lengkap',
                Cell: (row) => (
                    <div>
                        {Moment(row.original.created_at).format('DD MMMM YYYY')}
                    </div>
                )
            },

        ]

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.closeDialog()}>
                    <ModalHeader toggle={() => this.closeDialog()}>Invoice History</ModalHeader>
                    <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                        <ReactTable 
                            data={this.state.listHistoryInvoice}
                            columns={coloums}
                            pageSize={10}
                            showPagination={true}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            style={{
                                height: "800px"
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Finish</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderPreview(){
        const modalPreview = this.state.modalPreview;
        const url_doc = [{ uri:this.state.file_url }]
        const url_download = this.state.file_url;

        return(
            <Modal className="modal-dialog" isOpen={modalPreview} toggle={() => this.closePreview()} size="lg">
              <ModalHeader toggle={() => this.closePreview()}>Preview</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                <div>
                    <DocViewer 
                        pluginRenderers={DocViewerRenderers} 
                        documents={url_doc} 
                        config={{
                            header: {
                            disableHeader: false,
                            disableFileName: false,
                            retainURLParams: false
                            }
                        }}
                    />
                    <div className="form-row" style={{textAlign: "end"}}>
                        <div className="col-md-12">
                            <button onClick={(e) => this.downloadFile(e, url_download, this.state.file_url)} className="btn btn-outline-success" type="button"><i className="fa fa-download"></i></button>
                        </div>
                    </div>
                </div>
              </ModalBody>
            </Modal>
        )
    }

    render() {
        const { pageSize, multiSelectOption, myData } = this.props;
        const columns = [
            {
                Header: 'Student Code',
                accessor: 'code_students',
                Cell: (row) => (
                    <div>                     
                        <button id="UncontrolledTooltipExample" style={{border:'none'}} className='btn btn-outline-primary btn-sm btn-pill text-center' onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.token_students)}>{row.original.code_students}</button>
                    </div>
                ),
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_students"] }),
                    filterAll: true 
            },
            {
                Header: 'Student Name',
                accessor: 'nama',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama"] }),
                    filterAll: true 
            },
            {
                Header: 'School Name',
                accessor: 'nama_sekolah',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_sekolah"] }),
                    filterAll: true 
            },
            {
                Header: 'Total Amount',
                accessor: 'total_amount',
                Cell: (row) => (
                    <div>                     
                        <p>{row.original.new_currency} {Math.ceil(row.original.total_amount)}</p>
                    </div>
                ),
                style: {
                    textAlign: 'right'
                },
                sortable: true,
                filterable: false
            },
        ]

        return(
            <Fragment>
                <ReactTable 
                    data={myData}
                    columns={columns}
                    pageSize={100}
                    showPagination={true}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    style={{
                        height: "800px"
                    }}
                    SubComponent={row => {
                        const data = row.original.detail_invoice;
                        return (
                            <div style={{ padding: "20px" }}>
                              <div style={{paddingLeft:"10px", fontStyle:"", color:"#A6A6A6"}}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Code Invoice</th>
                                                <th>Code Loa</th>
                                                <th>Invoice Name</th>
                                                <th>Due Date</th>
                                                <th>Invoice Amount</th>
                                                <th>Status</th>
                                                <th>Note Payment</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((detail) => {
                                                return  <tr>
                                                            <td>{detail.code_invoice}</td>
                                                            <td>{detail.code_student_loa}</td>
                                                            <td>{detail.invoice_name}</td>
                                                            <td>{detail.due_date_format}</td>
                                                            <td>{detail.currency} {detail.invoice_amount}</td>
                                                            <td>
                                                            <div>
                                                                {
                                                                    detail.status_payment == '1' ?
                                                                    <>
                                                                        <span className={'badge'} style={{backgroundColor: 'green', color: 'white'}}>Paid</span>
                                                                    </>
                                                                    :
                                                                    detail.status_payment == '2' ?
                                                                    <>
                                                                        <span className={'badge'} style={{backgroundColor: 'rgb(255, 152, 0)', color: 'white'}}>Pending</span>
                                                                    </>
                                                                    :
                                                                    detail.status_payment == '3' ?
                                                                    <>
                                                                        <span className={'badge'} style={{backgroundColor: 'rgb(242, 60, 6)', color: 'white'}}>Cancel</span>
                                                                    </>
                                                                    :
                                                                    detail.status_payment == '4' ?
                                                                    <>
                                                                        <span className={'badge'} style={{backgroundColor: 'rgb(0, 91, 159)', color: 'white'}}>Sent</span>
                                                                    </>
                                                                    :
                                                                    detail.status_payment == '5' ?
                                                                    <>
                                                                        <span className={'badge'} style={{backgroundColor: 'rgb(255, 214, 0)', color: 'white'}}>Outstanding</span>
                                                                    </>
                                                                    :
                                                                    <span className={'badge'} style={{backgroundColor: 'rgb(99, 204, 255)', color: 'white'}}>New</span>
                                                                }
                                                            </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    detail.status_payment == '1' ?
                                                                    <div>
                                                                        {
                                                                            detail.note_payment != null ?
                                                                            <div>
                                                                            {
                                                                                detail.note_payment.split('.').pop() == 'pdf' ?
                                                                                <img style={{maxWidth:"91px"}} className='document-img' alt='images' onClick={() => this.filePreview(API_URL.baseSuperStudent+'/doc_remmitance/'+detail.code_invoice+'/'+detail.note_payment)} src={pdf} />
                                                                                :
                                                                                <img style={{maxWidth:"91px"}} className='document-img' alt='images' onClick={() => this.filePreview(API_URL.baseSuperStudent+'/doc_remmitance/'+detail.code_invoice+'/'+detail.note_payment)} src={API_URL.baseSuperStudent+'/doc_remmitance/'+detail.code_invoice+'/'+detail.note_payment} />
                                                                            }
                                                                            </div>
                                                                            :
                                                                            ""
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <p>{detail.note_payment}</p>
                                                                }
                                                            </td>
                                                            <td><button onClick={() => this.historyRow(detail)} className="btn btn-outline-danger btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-list" style={{ width: 35, fontSize: 16, padding: 11, color: 'red' }}></i></button></td>
                                                            <td><button onClick={() => this.editRow(detail)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'green' }}></i></button></td>
                                                        </tr>
                                            })}
                                        </tbody>            
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                        );
                    }}
                />
                <ToastContainer />
                {this.renderDialog()}
                {this.renderDialogHistory()}
                {this.renderPreview()}
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
    detailUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(DatatableDocument);